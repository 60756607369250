<template>
  <crud-base
    :url="url"
    :columns="columns"
    :onDelete="() => {}"
    :onUpdate="() => {}"
    :onStore="() => {}"
    :enableSearch="true"
    :checkAlerts="false"
    primaryKey="id"
  />
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
export default {
  name: "widgets-tab",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/widgets",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "TÍTULO", field: "titulo", sortable: false },
        { label: "DESCRIÇÃO", field: "descricao", sortable: false },
        { label: "TIPO", field: "tipo", sortable: false },
      ],
    };
  },
};
</script>
