<template>
  <div style="background: #fff">
    <CodeEditor />
<!--    <WidgetsTab />-->
  </div>
</template>

<script>

import WidgetsTab from "@/views/Dashboards/WidgetsTab.vue";
import CodeEditor from "@/components/form/CodeEditor.vue";

export default {
  name: "TestComponent",
  components: {
    CodeEditor,
    WidgetsTab
  },
  data() {
    return {
      tagsSelecionadas: [],
      colaborador: null,
      jsonData: {
        name: 'mike',
        age: 23,
        phone: '18552129932',
        address: ['AAA C1', 'BBB C2']
      }
    }
  }
}
</script>

<style scoped>

</style>