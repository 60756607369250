<template>
  <div>
    <codemirror
      v-model="codigo"
      :options="code_options"
    />
    <div class="row mt-1">
      <div class="col-9">
        <label><strong>Variáveis na query:</strong></label>
        <br>
        <span v-if="variaveis.length <= 0">
          Nenhuma variável definida em sua query
        </span>
        <span v-for="variavel in variaveis">
          <CBadge color="info" class="mr-1">
            {{ variavel }}
          </CBadge>
        </span>
      </div>
      <div class="col-3 text-right">
        <CButton color="primary" size="sm">
          Simular Query
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>

import {codemirror} from 'vue-codemirror'
// theme css
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/theme/zenburn.css'
// language js
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/display/autorefresh.js'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/mode/php/php'
// autoCloseTags
import 'codemirror/addon/edit/closetag.js'
export default {
  name: "CodeEditor",
  components: {
    codemirror
  },
  props: [
    'value',
    'scriptMode',
  ],
  data() {
    return {
      codigo: "SELECT * FROM V_BDTI WHERE :AUTH :ABC",

      // O regex abaixo segue o mesmo do backend, se mudar abaixo, precisa mudar no back também
      // app/Repositories/Core/CoreVariavelRepository.php
      regexVariaveis: /((?!\'[\w\s]*[\\']*[\w\s]*)((?<!:)(:[a-zA-Z]\w[a-zA-Z0-9\_]{0,}))(?![\w\s]*[\\']*[\w\s]*\'))/mg,
    }
  },
  computed: {
    variaveis() {
      let regex = RegExp(this.regexVariaveis);
      return [...new Set(this.codigo.match(regex) || [])];
    },
    code_options() {
      return {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: this.scriptMode === 'PHP' ? 'text/x-php' : 'text/x-sql',
        autoRefresh:true,
        lineWrapping: true,
        readOnly: false,
        disabled: true,
        theme: 'monokai',
        showCursorWhenSelecting: true,
        highlightSelectionMatches: {
          showToken: /\w/,
          annotateScrollbar: true
        },
      }
    }
  },
  watch: {
    codigo (novoCodigo) {
      this.$emit('input', novoCodigo);
    }
  }
}
</script>